<template>
  <v-card class="ma-3">
    <v-card-subtitle class="primary white--text text-center">
      Рабочие тех. карты
    </v-card-subtitle>
    <CardsTable
        url="/manufacture/card/index-active"
        :key="cardsTableUK"
        >
      <template v-slot:select="{ card }">
        <v-checkbox :value="card.id" v-model="selectedCards"></v-checkbox>
      </template>
      <template v-slot:name="{card}">
        <router-link :to="cardViewUrl(card)">{{card.name}}</router-link>
      </template>
    </CardsTable>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn
        color="success"
        :disabled="!selectedCards.length"
        @click="archiveCard"
        >
        В архив
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import CardsTable from './components/CardsTable.vue'

export default {
  components: { CardsTable },
  data: () => ({
    selectedCards: [],
    cardsTableUK: 1,
  }),
  computed: {
    ...mapState('organization', {
      orgId: state => state.profile.id,
    }),
  },
  methods: {
    cardViewUrl(card) {
      return card.module.baseUrl + '/card/view?id=' + card.moduleCardId
    },
    archiveCard() {
      this.$axios.post('/manufacture/card/archive', {
        ids: this.selectedCards
      }).then(r => {
        this.selectedCards = []
        this.cardsTableUK = Date.now()
      })
    },
  },
}
</script>
